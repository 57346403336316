@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FCF8F4;
  flex-direction: column;
  font-family: 'br_omny', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  /* margin-top: 10px;
  margin-bottom: 10px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


.dashboard-item {
  display: flex;
}

.subtitle {
  font-size: 9px;
  width: 300px;
  text-align: center;
  margin-top: 10px;
}

.recharts-area  {
  stroke: #FF746E !important;
  stroke-width: 5px !important;
  filter: drop-shadow(0px 15px 25px rgba(255, 116, 110, 0.30)) !important;
}