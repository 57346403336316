
@font-face {
    font-family: 'BR Omny';
    src: url('bromny-black-webfont.eot');
    src: url('bromny-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('bromny-black-webfont.woff2') format('woff2'),
         url('bromny-black-webfont.woff') format('woff'),
         url('bromny-black-webfont.ttf') format('truetype'),
         url('bromny-black-webfont.svg#br_omnyblack') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'BR Omny';
    src: url('bromny-blackitalic-webfont.eot');
    src: url('bromny-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('bromny-blackitalic-webfont.woff2') format('woff2'),
         url('bromny-blackitalic-webfont.woff') format('woff'),
         url('bromny-blackitalic-webfont.ttf') format('truetype'),
         url('bromny-blackitalic-webfont.svg#br_omnyblack_italic') format('svg');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'BR Omny';
    src: url('bromny-light-webfont.eot');
    src: url('bromny-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('bromny-light-webfont.woff2') format('woff2'),
         url('bromny-light-webfont.woff') format('woff'),
         url('bromny-light-webfont.ttf') format('truetype'),
         url('bromny-light-webfont.svg#br_omnylight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'BR Omny';
    src: url('bromny-lightitalic-webfont.eot');
    src: url('bromny-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('bromny-lightitalic-webfont.woff2') format('woff2'),
         url('bromny-lightitalic-webfont.woff') format('woff'),
         url('bromny-lightitalic-webfont.ttf') format('truetype'),
         url('bromny-lightitalic-webfont.svg#br_omnylight_italic') format('svg');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'br_omny';
    src: url('bromny-medium-webfont.eot');
    src: url('bromny-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('bromny-medium-webfont.woff2') format('woff2'),
         url('bromny-medium-webfont.woff') format('woff'),
         url('bromny-medium-webfont.ttf') format('truetype'),
         url('bromny-medium-webfont.svg#br_omnymedium') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'BR Omny';
    src: url('bromny-regular-webfont.eot');
    src: url('bromny-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('bromny-regular-webfont.woff2') format('woff2'),
         url('bromny-regular-webfont.woff') format('woff'),
         url('bromny-regular-webfont.ttf') format('truetype'),
         url('bromny-regular-webfont.svg#br_omnyregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'BR Omny';
    src: url('bromny-semibold-webfont.eot');
    src: url('bromny-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('bromny-semibold-webfont.woff2') format('woff2'),
         url('bromny-semibold-webfont.woff') format('woff'),
         url('bromny-semibold-webfont.ttf') format('truetype'),
         url('bromny-semibold-webfont.svg#br_omnysemibold') format('svg');
    font-weight: bold;
    font-style: normal;

}