/* .truncate {
    text-align: center;
    margin-top: 15px;
} */

.card-container {
}

.metric-card {
    flex-basis: 24%;
    width: 180px;
    margin: 10px;
}

.badge-wrapper {
    display: flex;
    justify-content: space-between;
}

.totalData-container {
    position: relative;
}

.hidden-analytics {
    opacity: 0.2;
}
    .metric-cards-container {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .metric-title {
        text-align: center;
        margin-bottom: 10px;
        font-size: 2.2rem;    
    }

    @media screen and (max-width: 1200px) {
        .tremor-Card-root {
            flex-direction: column !important;
        }
        .metric-card {
            width: 270px;
            margin: 10px;
        }        
        .metric-cards-container {
            margin-top: 25px;
            justify-content: center;
        }
        .tremor-Tab-root {
            font-size: 0.55rem !important;
        }
        .chart-view-card {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

 @media screen and (max-width: 720px) {
     .performance-view {
         display: none;
     }
 }   

 .custom-spark-area-chart  {
    box-shadow: none;
  }

  .data-graph-card  {
    box-shadow: none;
  }