
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.confirm-account-data {
  margin-top: 10px;
}


.aria-selected\:bg-tremor-background-emphasis[aria-selected="true"] {
  background-color: #FF746E !important;
}

.App-link {
  color: #61dafb;
}

.basic-single {
  width: 420px !important;
  margin: 8px;
}

.select-account-type {
  margin-bottom: 8px;
}

.select-account-type .select__control,
.basic-single .select__control {
  height: 48px;
  margin-top: 15px;
  border-radius: 10px;
  border: 1px solid #E4E4E4;
  background: #FCFCFC;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.button-back {
  padding: 5px 10px 5px 15px;
  font-size: 10px;
}

.title-h1 {
  margin-bottom: 20px;
}

.circle-loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
}

.welcome-page-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.settings-tabs {
  display: flex;
  flex-direction: row;
}

.settings-tab-list {
  border: none;
  margin-right: 80px !important;
  outline: none;
}

#activeSwitch[data-headlessui-state~="checked"] span {
  background: #FF746E !important;
}

#expiryDate button[data-headlessui-state="open"] {
  border: 1px solid #FF746E !important;
}

.settings-tabs .tremor-TabPanels-root .tremor-TabPanel-root {
  margin-top: 0px !important;
}